export default [

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/user/list',
    name: 'user-list',
    component: () => import('@/views/admin/user/list/UserList.vue'),
    meta: {
      resource: 'user',
      action: 'read',
    },
  },
  {
    path: '/user/new',
    name: 'user-new',
    component: () => import('@/views/admin/user/new-edit/NewUser.vue'),
    meta: {
      resource: 'user',
      action: 'create',
    },
  },
  {
    path: '/user/edit/:id',
    name: 'user-edit',
    component: () => import('@/views/admin/user/new-edit/EditUser.vue'),
    meta: {
      resource: 'user',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- STAFF ----------------------------------*
  // *===============================================---*
  {
    path: '/staff/list',
    name: 'staff-list',
    component: () => import('@/views/admin/staff/list/StaffList.vue'),
    meta: {
      resource: 'staff',
      action: 'read',
    },
  },
  {
    path: '/staff/new',
    name: 'staff-new',
    component: () => import('@/views/admin/staff/new-edit/NewStaff.vue'),
    meta: {
      resource: 'staff',
      action: 'create',
    },
  },
  {
    path: '/staff/edit/:id',
    name: 'staff-edit',
    component: () => import('@/views/admin/staff/new-edit/EditStaff.vue'),
    meta: {
      resource: 'staff',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- BRANCH ---------------------------------*
  // *===============================================---*
  {
    path: '/branch/list',
    name: 'branch-list',
    component: () => import('@/views/admin/branch/list/BranchList.vue'),
    meta: {
      resource: 'branch',
      action: 'read',
    },
  },
  {
    path: '/branch/new',
    name: 'branch-new',
    component: () => import('@/views/admin/branch/new-edit/NewBranch.vue'),
    meta: {
      resource: 'branch',
      action: 'create',
    },
  },
  {
    path: '/branch/edit/:id',
    name: 'branch-edit',
    component: () => import('@/views/admin/branch/new-edit/EditBranch.vue'),
    meta: {
      resource: 'branch',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- CATEGORY -------------------------------*
  // *===============================================---*
  {
    path: '/category/list',
    name: 'category-list',
    component: () => import('@/views/admin/category/list/CategoryList.vue'),
    meta: {
      resource: 'category',
      action: 'read',
    },
  },
  {
    path: '/category/new',
    name: 'category-new',
    component: () => import('@/views/admin/category/new-edit/NewCategory.vue'),
    meta: {
      resource: 'category',
      action: 'create',
    },
  },
  {
    path: '/category/edit/:id',
    name: 'category-edit',
    component: () => import('@/views/admin/category/new-edit/EditCategory.vue'),
    meta: {
      resource: 'category',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- ITEM -----------------------------------*
  // *===============================================---*
  {
    path: '/item/list',
    name: 'item-list',
    component: () => import('@/views/admin/item/list/ItemList.vue'),
    meta: {
      resource: 'item',
      action: 'read',
    },
  },
  {
    path: '/item/new',
    name: 'item-new',
    component: () => import('@/views/admin/item/new-edit/NewItem.vue'),
    meta: {
      resource: 'item',
      action: 'create',
    },
  },
  {
    path: '/item/edit/:id',
    name: 'item-edit',
    component: () => import('@/views/admin/item/new-edit/EditItem.vue'),
    meta: {
      resource: 'item',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- UNIT -----------------------------------*
  // *===============================================---*
  {
    path: '/unit/list',
    name: 'unit-list',
    component: () => import('@/views/admin/unit/list/UnitList.vue'),
    meta: {
      resource: 'unit',
      action: 'read',
    },
  },
  {
    path: '/unit/new',
    name: 'unit-new',
    component: () => import('@/views/admin/unit/new-edit/NewUnit.vue'),
    meta: {
      resource: 'unit',
      action: 'create',
    },
  },
  {
    path: '/unit/edit/:id',
    name: 'unit-edit',
    component: () => import('@/views/admin/unit/new-edit/EditUnit.vue'),
    meta: {
      resource: 'unit',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- LEDGER ---------------------------------*
  // *===============================================---*
  {
    path: '/ledger/list',
    name: 'ledger-list',
    component: () => import('@/views/admin/ledger/list/LedgerList.vue'),
    meta: {
      resource: 'ledger',
      action: 'read',
    },
  },
  {
    path: '/ledger/new',
    name: 'ledger-new',
    component: () => import('@/views/admin/ledger/new-edit/NewLedger.vue'),
    meta: {
      resource: 'ledger',
      action: 'create',
    },
  },
  {
    path: '/ledger/edit/:id',
    name: 'ledger-edit',
    component: () => import('@/views/admin/ledger/new-edit/EditLedger.vue'),
    meta: {
      resource: 'ledger',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- REASON ---------------------------------*
  // *===============================================---*
  {
    path: '/reason/list',
    name: 'reason-list',
    component: () => import('@/views/admin/reason/list/ReasonList.vue'),
    meta: {
      resource: 'reason',
      action: 'read',
    },
  },
  {
    path: '/reason/new',
    name: 'reason-new',
    component: () => import('@/views/admin/reason/new-edit/NewReason.vue'),
    meta: {
      resource: 'reason',
      action: 'create',
    },
  },
  {
    path: '/reason/edit/:id',
    name: 'reason-edit',
    component: () => import('@/views/admin/reason/new-edit/EditReason.vue'),
    meta: {
      resource: 'reason',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- TAG ------------------------------------*
  // *===============================================---*
  {
    path: '/tag/list',
    name: 'tag-list',
    component: () => import('@/views/admin/tag/list/TagList.vue'),
    meta: {
      resource: 'reason',
      action: 'read',
    },
  },
  {
    path: '/tag/new',
    name: 'tag-new',
    component: () => import('@/views/admin/tag/new-edit/NewTag.vue'),
    meta: {
      resource: 'reason',
      action: 'create',
    },
  },
  {
    path: '/tag/edit/:id',
    name: 'tag-edit',
    component: () => import('@/views/admin/tag/new-edit/EditTag.vue'),
    meta: {
      resource: 'reason',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- AUDIT LOG ------------------------------*
  // *===============================================---*
  {
    path: '/audit-log/list',
    name: 'audit-log-list',
    component: () => import('@/views/admin/audit-log/list/AuditLogList.vue'),
    meta: {
      resource: 'all',
      action: 'audit',
    },
  },
  {
    path: '/audit-log/view/:id',
    name: 'audit-log-view',
    component: () => import('@/views/admin/audit-log/list/ViewAuditLog.vue'),
    meta: {
      resource: 'all',
      action: 'audit',
    },
  },

  // *===============================================---*
  // *--------- CONFIG ---------------------------------*
  // *===============================================---*
  {
    path: '/setup-config',
    name: 'setup-config',
    component: () => import('@/views/admin/config/EditConfig.vue'),
    meta: {
      resource: 'branch',
      action: 'delete',
    },
  },

]
