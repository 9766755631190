import Vue from 'vue'
import store from '@/store'
// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // timeout: 1000,
  // headers: {'Authorization': 'Bearer ' + localStorage.getItem(jwtConfig.storageTokenKeyName)}
})

// Add request/response interceptor
axiosIns.interceptors.response.use(
  response => {
    store.state.app.isContentLoading = false
    return response
  },
  error => {
    store.state.app.isContentLoading = false
    // const { config, response: { status } } = error
    const { response } = error

    // if (status === 401) {
    if (response && response.status === 401 && response.data.message === 'Unauthorized') {
      // logout
      // Remove userData from localStorage
      localStorage.removeItem('accessToken')

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('staffData')

      // Reset ability
      // this.$ability.update([
      //   {
      //     action: 'read',
      //     subject: 'auth',
      //   },
      // ])

      // Redirect to login page
      window.location = '/login'
    }

    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
