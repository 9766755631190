export default [

  // *===============================================---*
  // *--------- SALE REGION ----------------------------*
  // *===============================================---*
  {
    path: '/sale-region/list',
    name: 'sale-region-list',
    component: () => import('@/views/sale/sale-region/list/SaleRegionList.vue'),
    meta: {
      resource: 'sale_region',
      action: 'read',
    },
  },
  {
    path: '/sale-region/new',
    name: 'sale-region-new',
    component: () => import('@/views/sale/sale-region/new-edit/NewSaleRegion.vue'),
    meta: {
      resource: 'sale_region',
      action: 'create',
    },
  },
  {
    path: '/sale-region/edit/:id',
    name: 'sale-region-edit',
    component: () => import('@/views/sale/sale-region/new-edit/EditSaleRegion.vue'),
    meta: {
      resource: 'sale_region',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- CLIENTS --------------------------------*
  // *===============================================---*
  {
    path: '/client/list',
    name: 'client-list',
    component: () => import('@/views/sale/client/list/ClientList.vue'),
    meta: {
      resource: 'client',
      action: 'read',
    },
  },
  {
    path: '/client/new',
    name: 'client-new',
    component: () => import('@/views/sale/client/new-edit/NewClient.vue'),
    meta: {
      resource: 'client',
      action: 'create',
    },
  },
  {
    path: '/client/upload',
    name: 'client-upload',
    component: () => import('@/views/sale/client/new-edit/NewClientImport.vue'),
    meta: {
      resource: 'client',
      action: 'create',
    },
  },
  {
    path: '/client/edit/:id',
    name: 'client-edit',
    component: () => import('@/views/sale/client/new-edit/EditClient.vue'),
    meta: {
      resource: 'client',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- SALES ----------------------------------*
  // *===============================================---*
  {
    path: '/sale/list',
    name: 'sale-list',
    component: () => import('@/views/sale/invoice/list/SaleList.vue'),
    meta: {
      resource: 'sale',
      action: 'read',
    },
  },
  {
    path: '/sale/new',
    name: 'sale-new',
    component: () => import('@/views/sale/invoice/new-edit/NewSale.vue'),
    meta: {
      resource: 'sale',
      action: 'create',
    },
  },
  {
    path: '/sale/edit/:id',
    name: 'sale-edit',
    component: () => import('@/views/sale/invoice/new-edit/EditSale.vue'),
    meta: {
      resource: 'sale',
      action: 'update',
    },
  },
  {
    path: '/sale/view/:id',
    name: 'sale-view',
    component: () => import('@/views/sale/invoice/list/ViewSale.vue'),
    meta: {
      resource: 'sale',
      action: 'read',
    },
  },

]
